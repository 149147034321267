.bcon-button{
    background-color: rgba(190, 68, 68, 1);
    color: white;
    border-radius: 20px;
}
.drop-shadow{
    box-shadow: 1px 1px white, inset 2px 2px 5px rgb(0, 0, 0);
}
.bcon-button:hover{
    cursor: pointer;
    background-color: rgb(165, 56, 56);;
}