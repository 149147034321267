@import '../../../styles/variables/mixins';
.events-body{
    padding: 3rem;
    @include tablet{
        padding: 2rem;
    }
    @include mobile{
        padding: .75rem;
    }
}
.category-select{
    // border: 1px solid black;
    padding: 1rem;
    text-align: center;
    border-radius: 2rem;
    appearance:none;
}
.service-heading{
    @include tablet{
        font-size: 1.5rem;
    }
    @include mobile{
        font-size: 1.2rem;
    }
}
.category-filter{
    width: 25%;
    @include tablet{
        width: 35%;
    }
    @include mobile{
        width: 60%;
    }
}