@import '../../../../styles/variables/mixins';

.testimonial-container{
    background-color: rgba(190, 68, 68, 0.1);
    border-radius: 40px;
    padding: 3rem;
    @include tablet{
        padding: 2rem;
    }
    @include mobile{
        padding: 1rem;
    }
}

.testimonial-description{
    @include tablet{
        width: 80%;
        font-size: 1rem;
    }
    @include mobile{
        width: 100%;
        font-size: 1rem;
    }
}