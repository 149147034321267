.admin-event-item{
    background-color: rgba(190, 68, 68, 0.05);
    border-radius: 20px;
}
.update-btn{
    width: 16px;
}
.update-btn-bin{
    width: 20px;
}
.update-btn:hover{
    cursor: pointer;
}