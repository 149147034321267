@import '../../styles/variables/mixins';

.bcon-hero-video{
    color: white;
    opacity: .3;
    animation: heroOpacity 5s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
}
.video-overlay-content{
    top: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
    right: 0 !important;
    animation: fadeOut 5s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    @include tablet{
      width: 75%;
    }
    @include mobile{
      width: 80%;
    }
}
@keyframes fadeOut {
  0%{
    opacity: 1;
  }
  100%{
    opacity: 0;
  }
}
@keyframes heroOpacity {
  0%{
    opacity: .3;
  }
  100%{
    opacity: 1;
  }
}
.bcon-logo-hero{
    border-right: 5px solid #be4545;
    padding: 0 3rem;
    @include tablet{
      padding: 0 1rem;
      border-right: 3px solid #be4545;
    }
    @include mobile{
      padding: 0 1rem;
      border-right: 3px solid #be4545;
    }
}
  .video-icon{
    width: 12rem;
    @include tablet{
      width: 5rem;
    }
    @include mobile{
      width: 3rem;
    }
}
.divdiv {
  display:inline-block;
  overflow:hidden;
}

.div-div {
  animation: showup 7s;
}

.div-div-div {
  width: 0px;
  animation: reveal 4s;
}

.div-div-div span {
  margin-left:-500px;
  animation: slidein 4s;
}

@keyframes showup {
    0% {opacity:0;}
    20% {opacity:1;}
}

@keyframes slidein {
    0% { margin-left:-800px; }
    20% { margin-left:-800px; }
    35% { margin-left:0px; }
}

@keyframes reveal {
    0% {opacity:0;width:0px;}
    25% {width:500px;opacity: 1;}
}
@keyframes showup {
  0% {opacity:0;}
  20% {opacity:1;}
  // 80% {opacity:1;}
  // 100% {opacity:0;}
}

@keyframes slidein {
  0% { margin-left:-800px; }
  20% { margin-left:-800px; }
  35% { margin-left:0px; }
  100% { margin-left:0px; }
}

@keyframes reveal {
  0% {opacity:0;width:0px;}
  20% {opacity:1;width:0px;}
  30% {width:355px;}
  80% {opacity:1;}
  100% {opacity:0;width:355px;}
}

.video-title{
  padding: 0 3rem;
  @include tablet {
    padding: 0 1rem;
    font-size: 2rem;
  }
  @include mobile {
    padding: 0 1rem;
    font-size: .75rem;
  }
}