@import '../../styles/variables/mixins';

.collage-container{
    width: 100%;
    border-radius: 20px;
    height: 400px;
    position: relative;
    background-color: black !important;
    @include tablet{
      height: 200px;
    }
    @include mobile{
      height: 150px;
    }
}
.image-1::-webkit-scrollbar,.image-2::-webkit-scrollbar,.image-3::-webkit-scrollbar { 
  display: none;  /* Safari and Chrome */
}
.image-1,.image-2,.image-3{
  width: 33.3%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  border-radius: 20px;
  border-top: 50px solid #b83131;
  @include tablet{
    border-top: 30px solid #b83131;
  }
  @include mobile{
    border-radius: 10px;
    border-top: 30px solid #b83131;
  }
}
.parallelogram{
  transform: skewX(-10deg);
}
.collage-container .image-1 img:nth-child(1),
.collage-container .image-2 img:nth-child(1),
.collage-container .image-3 img:nth-child(1){
  border-radius: 0px 0px 20px 20px;
}
.collage-container .image-1 img:last-child,
.collage-container .image-2 img:last-child,
.collage-container .image-3 img:last-child{
  border-radius: 0px 0px 20px 20px;
}

.collage-container .image-1 img, .collage-container .image-2 img,.collage-container .image-3 img{
    height: 100%;
    border-radius: 20px;
    width: 100%;
    // border: 5px solid white;
    @include tablet{
      border: 5px solid white;
    }
    @include mobile{
      border: 2px solid white;
    }
}
.border-red{
  border: 5px solid #be4545 !important;
  @include tablet{
    border: 3px solid #be4545 !important;
  }
  @include mobile{
    border: 2px solid #be4545 !important;
  }
}
.div-collage-container{
  border-radius: 12px 12px 0px 0px;
}
.image-1::before,.image-2::before,.image-3::before{
  position: absolute;
  top: 10px;
  opacity: .8;
  font-size: 1.1rem;
  text-align: center;
  color: white;
  z-index: 100;
  @include tablet{
    font-size: 1rem;
  }
  @include mobile{
    font-size: 0.6rem;
  }
}
.image-1::before{
  content: 'Music Festival & Concerts';
}
.image-2::before{
  content: 'Corporate & Exhibitons';
}
.image-3::before{
  content: 'Social & Weddings';
}
