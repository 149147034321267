@import '../../../styles/variables/mixins';

.service-body{
    padding: 3rem;
    @include tablet-desktop{
        padding: 2rem;
    }
    @include tablet{
        padding: 2rem;
    }
    @include mobile{
        padding: .75rem;
    }
}