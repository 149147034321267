@import '../../../styles/variables/mixins';

.service-modal-heading{
    font-size: 1.5rem;
    @include tablet{
        font-size: 1.2rem;
    }
    @include mobile{
        font-size: 1rem;
    }
}
.modal-content{
    color: white !important;
}
.modal-service-type{
    text-decoration: underline;
}
.info-modal{
    background-color: rgba(42, 42, 42, 1) !important;
    color: white !important;
}
.modal-dialog{
    min-width: 90%;
}
.year-of-event{
    color: rgba(42, 42, 42, 1);
    background-color: white;
    border-radius: 20px;
}
.location-icon-img{
    width: 16px;
}
.toggle-arrows{
    width: 50px;
}
.carousel-class{
    position: relative;
}
.left-arrow{
    z-index: 20;
    top: 0 !important;
    bottom: 0 !important;
    position: absolute;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: .5;
}
.right-arrow{
    z-index: 20;
    top: 0 !important;
    bottom: 0 !important;
    position: absolute;
    right: 0;
    display: flex;
    justify-content: center;
    opacity: .5;
    align-items: center;
}
.left-arrow:hover, .right-arrow:hover{
    cursor: pointer;
    opacity: 1;
}
.service-carousel .thumb{
    border: none !important;
}
.thumb.selected, .thumb:hover{
    border: 3px solid white !important;
}
.carousel-image{
    width: 50%;
    display: flex;
    justify-content: center;
    justify-self: center;
    align-self: center;
    height: 450px;
    @include tablet{
        width: auto;
        height: auto;
    }
    @include mobile{
        width: auto;
        height: auto;
    }
}
.slide{
    display: flex;
    justify-content: center;
}
.service-carousel .thumb{
    border-radius: 12px;
    @include tablet{
        width: 150px !important;
    }
    @include mobile{
        width: 100px !important;
    }
}
.service-carousel{
    margin-left: 3rem;
    margin-right: 3rem;
    @include tablet{
        font-size: 14px;
        margin-left: 2rem;
        margin-right: 2rem;
    }
    @include mobile{
        font-size: 12px;
        margin-left: 1rem;
        margin-right: 1rem;
    }
}

.carousel-image, .carousel-image img{
    border-radius: 12px;
}
.photo-gallery{
    @include mobile{
        display: none;
    }
}