@import '../../styles/variables/mixins';

.event-type{
    padding: .5rem 1.5rem;
    color: rgba(255, 255, 255, 0.5);
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 1.5rem;
    @include tablet{
        font-size: 14px;
    }
    @include mobile{
        font-size: 12px;
    }
}
.gaq-heading{
    @include tablet{
        font-size: 5rem;
    }
    @include mobile{
        font-size: 5rem;
    }
}
.event-types{
    @include tablet{
        width: 80% !important;
        justify-content: flex-start !important;
    }
    @include mobile{
        width: 100% !important;
        justify-content: flex-start !important;
    }
}
.form-part-gaq{
    margin-bottom: 3rem;
    @include tablet{
        margin-bottom: 2rem;
    }
    @include mobile{
        margin-bottom: 1rem;
    }
}
.event-type:hover{
    cursor: pointer;
}
.event-type-selected{
    background-color: rgba(190, 68, 68, 1);
    color: white;
    border: 1px solid rgba(190, 68, 68, 1);
}
.inputs{
    @include tablet{
        width: 70%;
    }
    @include mobile{
        width: 90%;
    }
}
.gaq-input{
    background:transparent;
    padding-bottom: 8px;
    border: none;
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}
.bcon-logo-modal{
    width: 150px;
    @include tablet{
        bottom: 0;
        left: 0;
        right: 0;
        width: 100px;
    }
    @include mobile{
        // display: none;
        bottom: 0;
        left: 0;
        right: 0;
        width: 50px;
    }
}
.close-btn-modal{
    margin: 2rem;
    width: 20px;
    right: 0;
    top: 0;
    @include tablet{
        width: 16px;
        margin: 1rem;
    }
    @include mobile{
        margin: 1rem;
        width: 16px;
    }
}
.modal-header{
   @include tablet{
    padding: 1rem;
    font-size: 1.5rem;
   }
   @include mobile{
    padding: .5rem;
    font-size: 1.5rem;
   }
}
.gaq-modal-body{
    padding: 3rem;
    @include tablet{
        padding: 1rem;
    }
    @include mobile{
        padding: .5rem !important;
    }  
}
.submit-btn:hover{
    cursor: pointer;
    border-bottom: 1px solid rgba(190, 68, 68, 1) !important;
}
.contact-details{
    margin-top: 3rem;
    @include tablet{
        margin-top: 2rem;
    }
    @include mobile{
        margin-top: 1rem;
        text-align: center;
    }
}
.top-inputs{
    @include tablet {
        // margin: 1rem 0.5rem;
        width: 100% !important;
        flex-direction: column !important;
    }
    @include mobile {
        width: 100% !important;
        flex-direction: column !important;
    }
}
.top-input{
    @include tablet {
        margin: 1rem 0.5rem;
        width: 100%;
    }
    @include mobile {
        margin: 1rem 0.5rem;
        width: 100%;
    }
}
.Toastify__toast--success {
    background: #046a21 !important;
    color: white !important;
  }
.Toastify__toast--error {
    background: #a42828 !important;
    color: white !important;
  }
.zindex{
    z-index: 100000000;
}