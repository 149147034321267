@import '../../styles/variables/mixins';

.testimonials{
    background-color: white;
}
.testimonials-heading{
        @include tablet{
            margin: auto 3rem;
            font-size: 5rem;
        }
        @include mobile{
            font-size: 4rem;
        }
}