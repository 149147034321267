@import '../../styles/variables/mixins';

.navbar-bg{
    z-index: 1000;
    position: fixed;
    // height: 10px;
    width: 100%;
    background: rgba(100,100,100,0.4);
    text-align: center;
    color: white;
    transition: top 0.6s;
}
.white-strip{
  background-color: white !important;
  top: 0;
}
.item{
    padding: 1.5rem 1.5rem;
    display: inline-block;
    position: relative;
    @include tablet{
      padding: 1.5rem 1rem;
      font-size: .8rem;
    }
    @include mobile{
      padding: 1.5rem .5rem;
      font-size: .8rem;
    }
    @include max-iphone6{
      padding: 1.5rem 0.5rem;
      font-size: .8rem;
    }
}
.nav-brand{
    display: flex;
    align-items: center;
    width: 3rem;
    margin: 1rem;
    position: absolute;
    left: 4rem;
    top: 0;
    bottom: 0;
    @include tablet{
      width: 2rem;
      margin: 0.5rem;
      left: 1rem;
    }
    @include mobile{
      width: 2rem;
      margin: 0.5rem;
      top: 0;
      bottom: 0;
      left: .5rem;
    }
    @include max-iphone6 {
      margin: 0.25rem;
    }
}
.brand-img{
    width: 100%;
}
.item:hover{
    cursor: pointer;
    // text-decoration: underline;
}

  
  .item::after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: red;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }
  
  .item:hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }
  .active-tab{
    box-shadow:inset 0px -2px 0px #f00;
  }
  .nav-gaq{
    right: 0 !important;
    top: 0 !important;
    bottom: 0 !important;
    margin: 2rem;
    @include tablet{
      width: 15%;
      font-size: 14px;
      margin: 1rem;
    }
    @include mobile{
      width: 25%;
      font-size: 12px;
      margin: auto .5rem;
    }
    @include max-iphone6{
      width: 20%;
      font-size: 10px;
      margin: 1rem;
    }
  }
  .nav-gaq-hide{
    display: none !important;
  }
  .uk-icon-nav{
    width: 20px;
    margin-right: .5rem;
}