@import '../../styles/variables/mixins';

.our-service-container{
    width: 100%;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    color: white;
    transition: background 1s;
}
.our-services-title{
    @include tablet{
        font-size: 5rem;
    }
    @include mobile{
        font-size: 4rem;
    }
}
.hidden{
    opacity: 0;
    transition: opacity .4s;
}
.opacity-zero{
    opacity: 0;
    transition: opacity .4s;
}
.read-more{
    text-decoration: underline;
    cursor: pointer;
}