@import '../../styles/variables/mixins';


.left-content{
  margin: 1rem 2rem 1rem 2rem;
}
.home-page{
  height: 100%;
  padding: 2rem;
}
.bconnect-logo-img{
  width: 8rem;
}
a{
  color: white !important;
}

.name{
  color: #E8040c;
}

.text-container{
  width: 60%;
  margin-top: 5rem;
}
.text-heading{
  font-size: 2.5rem;
}

.single-slider{
  display: none;
}
.connecting-text{
  font-weight: bold;
  font-size: 1.2rem;
  line-height: 1;
  margin-top: 2rem;
}
.coming-soon{
  border: dashed white 1px;
  display: inline-block;
  text-align: center;
}

@media only screen and (max-width: 1300px) {
  .home-page{
    padding: 1rem;
  }
  .left-content{
    margin: 2rem;
  }
  .text-heading{
    font-size: 2rem;
  }
  .text-container{
    width: 70%;
    margin-top: 3rem;
  }
  .right-content{
    width: 50%;
  }
  .bconnect-logo-img{
    width: 7rem;
  }
}

@include mobile {
  .text-heading{
    font-size: 1.5rem;
    font-weight: bold;
  }
  .right-content{
    display: none;
  }
  .single-slider{
    display: block;
  }
  .bconnect-logo-img{
    width: 100px;
  }
  .text-container{
    margin-top: 2rem;
    width: 100%;
  }
  .left-content{
    margin: 1rem !important;
    width: 50%;
  }
  .text-subheading{
    font-size: .9rem;
  }
  .home-page{
    padding: .5rem !important;
  }
  .connecting-text{
    font-weight: bold;
    font-size: 0.8rem;
    line-height: 1;
    margin-top: 2rem;
  }
  .coming-soon{
    font-size: .8rem;
  }
  .name-details{
    line-height: 1.3;
    margin: 10px 0px 10px 0px;
  }
}

@media only screen and (max-width: 400px) {
  .text-container{
    margin-top: 1.5rem;
  }
  .text-heading{
    font-size: 1.2rem;
  }
  .text-subheading{
    font-size: .8rem;
  }
  .name-details{
    margin: 5px 0px 5px 0px;
  }
  .coming-soon{
    font-size: .8rem;
  }
}