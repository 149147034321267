@import '../../styles/variables/mixins';

.footer{
    color: black !important;
    @include tablet{
        flex-direction: column;
        padding: 0 !important;
    }
    @include mobile{
        flex-direction: column;
        padding: 0 !important;
    }
}
.collabs{
    @include tablet{
        font-size: .8rem;
    }
    @include mobile{
        font-size: .8rem;
    }
}
.footer-left-heading{
    @include tablet{
        width: 100%;
        font-size: 2rem;
    }
    @include mobile{
        width: 100%;
        font-size: 1.5rem;
    }
}
.font-today{
    @include tablet{
        font-size: 3rem;
    }
    @include mobile{
        font-size: 2.5rem;
    }
}
.uk-icon{
    width: 24px;
    margin-right: .5rem;
}
.footer-items{
    color: black;
    font-size: 16px;
    word-break: break-all;
    @include tablet{
        font-size: 12px;
    }
    @include mobile{
        font-size: 10px;
    }
}
.footer-heading{
    font-weight: bold;
    @include tablet{
        font-size: 14px;
    }
    @include mobile{
        font-size: 12px;
    }
}
.footer-link{
    font-size: 1.8rem;
    margin: 0px 1rem;
    @include tablet{
        font-size: .8rem !important;
    }
    @include mobile{
        font-size: .8rem !important;
    }
}
.footer-link:hover{
    cursor: pointer;
}
.lower-items{
    font-size: 1.2rem;
    margin: 0px 2rem;
    width: 100%;
    @include tablet{
        margin: 0px .5rem;
    }
    @include mobile{
        margin: 0px .5rem;
    }
}
.footer-left{
    margin: .5rem;
    @include tablet{
        border-radius: 0 !important;
        width: 100%;
        padding: 1rem !important;
        margin: 0;
    }
    @include mobile{
        border-radius: 0 !important;
        width: 100%;
        padding: 1rem !important;
        margin: 0;
    }
}
.footer-right{
    padding: 3rem 3rem 1rem 3rem;  
    margin: .5rem;

    @include tablet{
        border-radius: 0 !important;
        width: 100%;
        padding: 1rem !important;
        margin: 0;
    }
    @include mobile{
        border-radius: 0 !important;
        width: 100%;
        padding: 1rem !important;
        margin: 0;
    }
    
}
.bcon-logo-footer{
    @include tablet{
        width: 10%;
    }
    @include mobile{
        // display: none;
    }
}
.footer-left-container{
    @include tablet{
        width: 100%;
    }
    @include mobile{
        width: 100%;
    }
}
.contact-us-btn{
    @include tablet{
        width: 30%;
    }
    @include mobile{
        width: 30%;
    }
}
.border-radius{
    border-radius: 10px;
}
.dribble-link{
    color: black !important;
}
.designed-by{
    @include tablet{
        font-size: 14px;
        margin-top: 1.5rem !important;
    }
    @include mobile{
        font-size: 12px;
        margin-top: 1.5rem !important;
    }
}