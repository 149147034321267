@import '../../styles/variables/mixins';

.connect-with-bconnect{
    background-color: white;
    @include tablet{
        flex-direction: column;
    }
    @include mobile{
        flex-direction: column;
    }
}
.left-section{
    padding: 3rem;
}
.left-section, .right-section{
    @include tablet{
        padding: 1rem !important;
        width: 100%;
    }
    @include mobile{
        width: 100%;
        padding: 1rem !important;
    }
}
.connect-with-collage{
    width: 100%;
    margin: auto;
    float: right;
    @include tablet{
        width: 100%;
    }
    @include mobile{
        width: 100%;
    }
}
.bconnect{
    @include tablet{
        font-size: 5rem;
    }
    @include mobile{
        font-size: 5rem;
    }
}