.create-events-form{
    background-color: white;
}

.events-form-label{
    color: black;
}
.img-bin{
    width: 30px;
    top: 0px;
    right: 0px;
}
.img-bin:hover{
    cursor: pointer;
}
.uploaded-images{
    overflow-y: scroll;
    max-height: 700px;
}
.col-col{
    margin: 1rem 0;
}
.uploaded-img{
    border-radius: 10px;
}
.uploaded-img-img{
    box-shadow: 3px 3px 3px black;
    border-radius: 15px;
}
.uploaded-img-img:hover{
    cursor: pointer;
}
.cover-img{
    border: 5px solid #be4545;
    border-radius: 15px;
}
.Toastify__toast--success {
    background: #046a21 !important;
    color: white !important;
  }
.Toastify__toast--error {
    background: #a42828 !important;
    color: white !important;
  }