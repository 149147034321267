.update-events{
    background-color: white;
}
.create-event-btn-btn{
    background-color: #be4545;
    border-radius: 12px;
    cursor: pointer;
}
.create-event-btn-btn:hover{
    background-color: #9a3a3a;
}
