@import '../../../../styles/variables/mixins';

.info-modal{
    background-color: rgba(42, 42, 42, 1) !important;
    color: white !important;
}
.modal-dialog{
    min-width: 90%;
}
.carousel .control-prev.control-arrow{
    left: -10px !important;
}
.carousel .control-next.control-arrow{
    right: -10px !important;
}
.year-of-event{
    color: rgba(42, 42, 42, 1);
    background-color: white;
    border-radius: 20px;
    padding: .25rem 2rem;
    @include tablet{
        border-radius: 15px;
        padding: .25rem .5rem;
        font-size: 1rem;
    }
    @include mobile{
        border-radius: 15px;
        padding: .25rem .5rem;
        font-size: 1rem;
    }
}
.location-icon-img{
    width: 16px;
    @include tablet{
        width: 14px;
    }
    @include mobile{
        width: 12px;
    }
}
.location{
    font-size: 1rem;
    @include tablet{
        font-size: 14px;
    }
    @include mobile{
        font-size: 14px;
    }
}
.toggle-arrows{
    width: 50px;
}
.carousel-class{
    position: relative;
    margin-left: 3rem;
    margin-right: 3rem;
    @include tablet{
            margin-left: 2rem;
            margin-right: 2rem;
    }
    @include mobile{
            margin-left: .5rem;
            margin-right: .5rem;
    }
}
.left-arrow{
    z-index: 20;
    top: 0 !important;
    bottom: 0 !important;
    position: absolute;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: .5;
}
.right-arrow{
    z-index: 20;
    top: 0 !important;
    bottom: 0 !important;
    position: absolute;
    right: 0;
    display: flex;
    justify-content: center;
    opacity: .5;
    align-items: center;
}
.left-arrow, .right-arrow{
    // padding: 1.5rem;
    width: 2rem;
    @include tablet{
        padding: .5rem;
        width: 2rem;
    }
    @include mobile{
        padding: .5rem;
        width: 2rem;
    }
}
.left-arrow:hover, .right-arrow:hover{
    cursor: pointer;
    opacity: 1;
}
.thumb{
    border: none !important;
    border-radius: 12px;
}
.thumb.selected, .thumb:hover{
    border: 3px solid white !important;
}
.btn-icon:hover{
    cursor: pointer;
}
.modal-left-content, .modal-right-content{
    padding: 0 1rem;
    @include tablet{
        width: 100%;
        padding: 0 .75rem;
    }
    @include mobile{
        width: 100%;
        padding: 0 .5rem;
    }
}
.event-modal-heading{
    @include tablet{
        font-size: 2rem;
    }
    @include mobile{
        font-size: 1.5rem;
        margin-bottom: .5rem;
    }
}
.modal-body-container{
    @include tablet{
        flex-direction: column;
    }
    @include mobile{
        flex-direction: column;
    }
}
.event-modal-header{
    margin-bottom: 3rem;
    @include tablet{
        margin-bottom: 2rem;
    }
    @include mobile{
        margin-bottom: 1.5rem;
    }
}
.event-modal-cta{
    width: 50;
    margin-bottom: 2rem;
    @include tablet{
        width: 70%;
    }
    @include mobile{
        width: 70%;
    }
}
.event-modal-description{
    @include tablet{
        font-size: 1rem;
    }
    @include mobile{
        font-size: .9rem;
    }
}
.event-modal-img-container, .event-modal-img{
     border-radius: 12px;
}