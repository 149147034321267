@import '../src/styles/variables/colors';
@import '../src/styles/variables/mixins';

$tablet-width: 768px;

html, body {
    background: #000 !important;
    font-size: 16px;
    padding-top: 3.2rem;
    font-family: 'Barlow Regular';
    color: white;
    // scroll-behavior: smooth !important;
}
.fixed-button {
  position: fixed;
  bottom: 40px; /* Adjust as needed */
  right: 40px; /* Adjust as needed */
  border: none;
  width: 70px;
  background-color: #be4545;
  border-radius: 50%;
  z-index: 1000000;
  box-shadow: 0px 0px 10px black;
  cursor: pointer;
}
.barlow-font-regular{
  font-family: 'Barlow Regular' !important;
}
.barlow-font-medium{
  font-family: 'Barlow Medium' !important;
}
.barlow-font-bold{
  font-family: 'Barlow Bold' !important;
}
.bearpaw-font{
  font-family: 'Bearpaw' !important;
}
.font-bold{
  font-weight: bold;
}
.font-bold-1{
  font-weight: 100;
}
.font-bold-2{
  font-weight: 200;
}
.font-bold-3{
  font-weight: 300;
}
.font-bold-4{
  font-weight: 400;
}
.font-bold-5{
  font-weight: 500;
}
.font-bold-6{
  font-weight: 600;
}
.font-bold-7{
  font-weight: 700;
}
.w-100{
  font-weight: 100;
}
.w-200{
  font-weight: 200;
}
.w-300{
  font-weight: 300;
}
.w-400{
  font-weight: 400;
}
.w-500{
  font-weight: 500;
}
.w-600{
  font-weight: 600;
}
.w-700{
  font-weight: 700;
}

.font-1{
  font-size: .1rem; 
}
.font-2{
  font-size: .2rem; 
}
.font-3{
  font-size: .3rem; 
}
.font-4{
  font-size: .4rem; 
}
.font-5{
  font-size: .5rem; 
}
.font-6{
  font-size: .6rem; 
}
.font-7{
  font-size: .7rem; 
}
.font-8{
  font-size: .8rem; 
}
.font-9{
  font-size: .9rem; 
}
.font-12{
  font-size: 1.2rem;
}
.font-15{
  font-size: 1.5rem;
}
.font-18{
  font-size: 1.8rem;
}
.font-20{
  font-size: 2rem;
}
.font-25{
  font-size: 2.5rem;
}  
.font-30{
  font-size: 3rem;
}
.font-40{
  font-size: 4rem;
}
.font-50{
  font-size: 5rem;
}
.font-60{
  font-size: 6rem;
}
.font-70{
  font-size: 7rem;
}
.font-80{
  font-size: 8rem;
}
.font-90{
  font-size: 9rem;
}
.font-100{
  font-size: 10rem;
}


.width-10{
  width: 10%;
}
.width-15{
  width: 15%;
}
.width-20{
  width: 20%;
}
.width-25{
  width: 25%;
}
.width-30{
  width: 30%;
}
.width-35{
    width: 35%;
}  
.width-40{
  width: 40%;
}
.width-45{
  width: 45%;
}
.width-50{
  width: 50%;
}
.width-60{
  width: 60%;
}
.width-70{
  width: 70%;
}
.width-80{
  width: 80%;
}
.width-90{
  width: 90%;
}
.width-100{
  width: 100%;
}
.bcon-text-red{
  color: $bcon-text-red;
}
.bcon-background{
  background-color: rgba(28, 28, 28, 1) !important;
}
.service-heading{
  border-bottom: 2px solid #be4545;
  padding-bottom: 5px;
}
.fade-in-animation{
  animation: fadein 1s;
}
.spinner-container {
  position: absolute;
  top: 100px;
  left: 0;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  width: 100%;
  z-index: 1002;
  border-top-left-radius: 24px;
  border-bottom-left-radius: 24px;
  display: flex;
  align-items: center;
  justify-content: center;

  &.not-rounded {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  @include mobile {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    position: fixed;

    .spinner {
      position: static;
    }
  }
}
.spinner {
  position: absolute;
  left: 50%;
  top: 50%;
  height: 60px;
  width: 60px;
  margin: 0px auto;
  -webkit-animation: rotation 0.6s infinite linear;
  -moz-animation: rotation 0.6s infinite linear;
  -o-animation: rotation 0.6s infinite linear;
  animation: rotation 0.6s infinite linear;
  border-left: 6px solid rgba(239, 0, 0, 0.15);
  border-right: 6px solid rgba(239, 0, 0, 0.15);
  border-bottom: 6px solid rgba(239, 0, 0, 0.15);
  border-top: 6px solid #be4545;
  border-radius: 100%;
}
@-webkit-keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
  }
}
@-moz-keyframes rotation {
  from {
    -moz-transform: rotate(0deg);
  }
  to {
    -moz-transform: rotate(359deg);
  }
}
@-o-keyframes rotation {
  from {
    -o-transform: rotate(0deg);
  }
  to {
    -o-transform: rotate(359deg);
  }
}
@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
.responsive-padding{
  padding: 3rem;
  @include tablet{
      padding: 2rem;
  }
  @include mobile{
      padding: 1rem;
  }
}
@keyframes fadein {
  from {
      opacity:0;
  }
  to {
      opacity:1;
  }
}