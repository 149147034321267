@import '../../../../styles/variables/mixins';

.event-card{
    width: 22%;
    margin-left: 15px;
    margin-right: 15px;
    border-radius: 1.5rem;
    box-shadow: 0px 5px 10px rgb(189, 187, 187);
    @media screen and (max-width: 1200px) {
        width: 22%;
    }
    @media screen and (max-width: 1024px) {
        width: 30%;
    }
    @include tablet{
        width: 30%;
        margin-left: 12px;
        margin-right: 12px;
     }
     @include mobile{
        width: 45%;
        margin-left: 7px;
        margin-right: 7px;
        border-radius: 0;
     }

}
.event-card:hover{
    cursor: pointer;
}
.browse-icon-img{
    width: 1rem;
    @include tablet{
        width: 12px;
    }
    @include mobile{
        width: 10px;
    }
}
.event-card-img{
    border-radius: 1.5rem;
    @include tablet{
        border-radius: 1.5rem;
     }
     @include mobile{
        border-radius: 0;
     }
}
.event-img{
    display: flex;
    align-items: center;

}
.event-card-name,.event-card-year{
    @include tablet{
       font-size: 14px;
    }
    @include mobile{
        font-size: 14px;
    }
}
.card-details{
    padding: 2rem;
    @include tablet{
        padding: 2rem;
     }
     @include mobile{
        padding: 1rem;
     }
}
.event-type-image {
    border-radius: 30px;
    height: 0;
    width: 100%;
    padding-bottom: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
    height: 300px;
    @include tablet{
        height: 300px;
        border-radius: 20px;
    }
    @include mobile{
        height: 300px;
        border-radius: 0px;
    }
    @include iphone6{
        height: 150px;
        border-radius: 0px;
    }
}
.event-card .carousel-inner, .event-carousel-item{
    border-radius: 30px;
    @include tablet{
        border-radius: 20px;
    }
    @include mobile{
        border-radius: 0px;
    }
}