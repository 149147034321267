
@import '../../styles/variables/colors';

.confirm-delete{
    background-color: #be4545;
    border-radius: 12px;
    cursor: pointer;
}
.confirm-delete:hover{
    background-color: #783c3c;
}