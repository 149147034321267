@import '../../../src/styles/variables/mixins';
.our-clients{
    padding: 3rem;
    @include tablet{
        padding: 2rem;
    }
    @include mobile{
        padding: 1rem;
    }
}
.our-clients-div{
    margin: 0px 2rem;
    width: 10%;
    @include tablet{
        width: 25% !important;
        margin: 0px 1rem;
    }
    @include mobile{
        width: 20% !important;
        margin: 0px .5rem;
    }
}
.our-clients-img{
    width: 100%;
}
.overlay-cls{
    filter: grayscale(100%);
}
.our-clients-div:hover{
    cursor: pointer;
    filter: none !important;
}
.big-img{
    width: 20%;
}
.white-bg{
    // padding: 1rem;
    background: #fff;
}
.clients-group{
    margin: .5rem 0;
}