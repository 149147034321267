@import '../../styles/variables/mixins';

.marquee-wrapper{
    position: fixed;
    left: 40vw;
    top: 0px;
    text-align: right;
}
.marquee-item img {
    max-width: 100%; /* Ensure the image fits within the MarqueeItem */
    max-height: 100%; /* Ensure the image fits within the MarqueeItem */
    display: block; /* Remove any extra spacing around images */
    animation: fadeOutFadeIn 30s linear infinite
  }

.marquee-wrapper .container{
    height: 100vh;
    width: 60vw;
    overflow:hidden;
    text-align:center;
}
.marquee-inner span{
    height:100vh    // margin:1px;
}
.marquee-wrapper .marquee-block{
    width: 15rem;
    height: 100vh;
    overflow: hidden;
    box-sizing: border-box;
    position: relative;
    float:left;
}
.marquee-inner{
    display: block;
    position: absolute;
    width: 30%;
}

@keyframes fadeOutFadeIn {
    0%, 100% {
      opacity: 0;
    }
    2%,98%{
        opacity: 0.1;
    }
    5%,95%{
        opacity: 1;
    }
  }

.marquee-item p{
    font-weight: 800;
    font-size: 30px;
}

.marquee-inner.to-left{
    animation: marqueeTop 30s linear infinite;
}

.marquee-inner.to-right{
    animation: marqueeBottom 30s linear infinite;
}

.marquee-item{
    display: block;
    transition: all .5 ease-in;
}

.strip-category{
    color: black;
    background-color: white;
    text-align: center;
    width: 100%;
    padding: 1rem;
    margin: 10px 0px 10px 0px;
    font-weight: bold;
    font-size: 14px;
    text-transform: uppercase;
    animation: fadeOutFadeIn 30s linear infinite
}
@keyframes marqueeTop{
    0% {
        top: 0;
    }
    100%{
        top: -75%;
    }
}

@keyframes marqueeBottom{
    0% { 
        top: -75%; 
    }
    100% {
        top: 0; 
    }
}


@media only screen and (max-width: 1300px) {
    .marquee-wrapper .marquee-block{
        width: 12rem;
    }
}

@include mobile{
    .container{
        padding: 0 !important;
    }
    .marquee-wrapper .marquee-block{
        width: 9rem;
    }
}