@import '../../../../styles/variables/mixins';

input, .git-select{
    border: none;
    border-bottom: 1px solid black;
}
.git-input, .git-select{
    @include tablet{
        font-size: 1rem;
    }
    @include mobile{
        font-size: 1rem;
    }
}
input:focus, select:focus{
    outline: none;
}
.git-form{
    @include tablet{
        width: 80%;
    }
    @include mobile{
        width: 80%;
    }
}