@import '../../../../styles/variables/mixins';

.service-type-left{
    background-color: rgba(190, 68, 68, 0.05);
    margin-left: 1rem;
    width: 60%;
    margin-right: 1rem;
    border-radius: 30px;
    @include tablet-desktop{
        margin-left: 1rem;
        width: 60%;
        margin-right: 1rem;
        border-radius: 30px;
    }
    @include tablet{
        margin-left: 1rem;
        width: 60%;
        margin-right: 1rem;
        border-radius: 10px;
    }
    @include mobile{
        margin-left: 0;
        margin-right: 0;
        width: 60%;
        border-radius: 5px;
    }
}
.service-type-image, .service-type-img {
    border-radius: 30px;
    width: 500px;
    background-repeat: no-repeat;
    background-size: cover;
    // height: 400px;
    @include tablet{
        max-height: 300px;
        width: 300px;
        border-radius: 10px;
    }
    @include mobile{
        height: 100%;
        width: 200px;
        border-radius: 5px;
    }
}

.service-type-left:hover .bcon-button{
    border: 1px solid white;
}
.service-type .service-carousel{
    border-radius: 30px;
    width: 40% !important;
    @include tablet{
        width: 40% !important;
        margin: 0;
        border-radius: 10px;
    }
    @include mobile{
        width: 40% !important;
        margin: 0;
        border-radius: 5px;
    }
}
.service-type .carousel-inner{
    border-radius: 30px;
    @include tablet{
        width: 100%;
        border-radius: 10px;
    }
    @include mobile{
        width: 100%;
        border-radius: 5px;
    }

}
.service-type-heading{
    @include tablet{
        font-size: 1rem;
    }
    @include mobile{
        font-size: .8rem;
    }
}
.service-type-description{
    margin-bottom: 3rem;
    margin-top: 1rem;
    @include tablet{
        font-size: 12px;
        margin-bottom: 1rem;
        margin-top: 1rem;
    }
    @include mobile{
        font-size: 10px;
        margin-bottom: .5rem;
        margin-top: .5rem;
    }
}
.service-type-right{
    @include tablet{
        // width: 100%;
    }
    @include mobile{
        // width: 100%;
    }
}
.left-content-service{
    padding: 3rem;
    @include tablet-desktop{
        padding: 2rem;
        width: 100%;
    }
    @include tablet{
        padding: 1rem;
        width: 100%;
    }
    @include mobile{
        padding: .5rem .75rem;
        width: 100%;
    }
}
.bcon-service-btn{
    margin: 3rem;
    margin-bottom: 2rem;
    @include tablet-desktop{
        font-size: 1.2rem;
        width: 30%;
        margin: 1rem;
        margin-bottom: 2rem;
    }
    @include tablet{
        font-size: 1rem;
        width: 40%;
        margin: 1rem;
    }
    @include mobile{
        margin: 0.5rem;
        width: 60%;
        font-size: .6rem;
    }
}

.service-carousel-img{
    width: 400px;
    height: 550px;
    @include tablet-desktop{
        width: 300px;
        height: 400px;
    }
    @include tablet{
        width: 300px;
        height: 300px;
    }
    @include mobile{
        width: 150px;
        height: 200px;
    }
}
.service-type{
    margin-top: 3rem;
    margin-bottom: 3rem;
    @include tablet-desktop{
        margin-top: 1rem;
        margin-bottom: 1rem;
    }
    @include mobile{
        margin-top: 1rem;
        margin-bottom: 1rem;
    }
}
.read-more{
    color: #be4545;
}
.service-type-left:hover .read-more{
    color: white;
}