@import '../../../styles/variables/mixins';

.service-hero{
    // background-image: url('./../../../assets/Music/music-fest-6.jpg') !important;
    // height: 35vh;
    background-color: white;
    width: 100%;
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
    color: white;
}
.services-font{
    @include tablet{
        font-size: 7rem !important;
    }
    @include mobile{
        font-size: 5rem !important;
    }
}
.hero-img-banner{
    width: 80%;
}