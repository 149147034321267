@import '../../../styles/variables/mixins';

.service-circle{
    border: 2px solid gray;
    border-radius: 50%;
    width: 325px;
    height: 325px;
    margin: 0 1rem !important;
    transition: background .5s;
    @include tablet{
        width: 250px;
        height: 250px;
        margin: 0 .5rem !important;
    }
    @include mobile{
        width: 80px;
        height: 80px;
        margin: 0 .5rem !important;
    }
}
.service-name{
    text-transform: uppercase;
    font-size: 1.5rem;
    @include tablet{
        font-size: 1rem;
    }
    @include mobile{
        font-size: 0.65rem;
    }
}
.service-circle:hover{
    background-color: rgba(255, 255, 255, 0.358);
    color: black;
    cursor: pointer;
    border: none;
    animation: scaleDown 1s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
}

@keyframes scaleDown {
    0% {
        transform: scale(1,1);
    }
    100% {
        transform: scale(.9, .9);
    }
}