@import '../../styles/variables/mixins';

.we-are-content{
    width: 100%;
    padding-bottom: 75%;

}
.we-are-section{
    padding: 3rem;
    box-sizing: border-box;
    resize: horizontal;
    overflow: auto;
    max-width: 100%;
    height: calc(100vh - 16px);
    background: url('./../../assets/we-are-banner.png') no-repeat fixed;
    height: 100vh;
    // width: 2000px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    // image-rendering: -webkit-optimize-contrast; 
    color: white;
    @include tablet{
        padding: 2rem;
        height: 60vh;
    }
    @include mobile{
        padding: 1rem;
        height: 40vh;
    }
}
.we-are{
    @include tablet{
        font-size: 3rem;
        width: 100% !important;
    }
    @include mobile{
        font-size: 1.3rem;
        width: 100% !important; 
    }
}
.we-are-title{
    @include tablet{
        font-size: 1.5rem;
    }
    @include mobile{
        font-size: .8rem;
    }
}
.we-are-title span{
    @include tablet{
        margin: 0 .5rem;
    }
    @include mobile{
        margin: 0 .5rem !important;
    }
}

.we-are-content{
    font-size: 20px;
    padding: 1rem;
    @include tablet{
        padding: 1rem;
        font-size: 14px !important;
        width: 80%;
    }
    @include mobile{
        padding: 1rem;
        width: 100%;
        // margin-top: 16px;
        font-size: 12px !important;
    }
    @include max-iphone6{
        font-size: 8px !important;
    }
}
.we-are-description{
    display: flex;
    margin-bottom: 3rem;
    margin-top: 3rem;
    padding-bottom: 3rem;
    @include tablet{
        margin: auto;
        padding: 1rem;
        font-size: 14px;
        width: 80%;
        margin-top: 2rem;
        padding-bottom: 2rem;
        margin-bottom: 2rem;
        margin-top: 2rem;
    }
    @include mobile{
        margin: auto;
        margin-top: 2rem;
        font-size: 10px;
        width: 90%;
        padding-bottom: 2rem;
        margin-bottom: 1rem;
        margin-top: 1rem;
    }
}