@import '../../styles/variables/mixins';

body {
    background: #000;
    color: #fff;
  }
  
.App {
    font-family: sans-serif;
    text-align: center;
    position: absolute;
    bottom: -30rem;
    left: -15rem;
}

@media only screen and (max-width: 1400px) {
    .App{
        bottom: -30rem;
        left: -15rem;
    }
}


@media only screen and (max-width: 1000px) {
    .App{
        bottom: -30rem;
        left: -22rem;
    }
}

@media only screen and (max-width: 500px) {
    .App{
        bottom: -30rem;
        left: -22rem;
    }
}

@media only screen and (max-width: 400px) {
    .App{
        bottom: -32rem;
        left: -25rem;
    }
}
a {
    color: royalblue;
}
  