@import '../../../../src/styles/variables/mixins';
.founders-note-imgs{
    padding: 3rem;
    width: 100%;
    @include tablet{
        flex-direction: column;
    }
    @include mobile{
        flex-direction: column;
    }
}
.founders-note-img{
    width: 550px;
    @include mobile{
        width: 350px;
    }
}